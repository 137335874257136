<template>
    <section class="content">
        <div class="container-fluid">
                <div class="alert alert-dark alert-dismissible fade show" role="alert">
                    <strong>Welcome!</strong> You should fill the rest of your <router-link to="/profile">profile information</router-link>!
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

            <div class="row ">
                <!-- /.col -->

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-up"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Commissions</span>
                            <span class="info-box-number">{{commissionTotal}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Sales</span>
                            <span class="info-box-number">{{salesTotal}}</span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-users"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Referrals</span>
                            <span class="info-box-number">{{referrals}}</span>
                        </div>
                    <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Properties</span>
                            <span class="info-box-number">{{propertyTotal}}</span>
                        </div>
                    <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="info-box mb-3">
                    <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-heart"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text" v-for="referredBy in referred" :key="referredBy.id">
                                <b>Upline name:</b> {{referredBy.name}} &nbsp; &nbsp; &nbsp;
                                <b>Upline's mobile no:</b> {{referredBy.phone}}</span>
                            <span class="info-box-text">
                                <b>Invitation Link: </b>{{getRoute()}}/join?ref={{profile.affiliate_id}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                <!-- Info Boxes Style 2 -->
                    <div class="info-box mb-3 bg-secondary">
                        <span class="info-box-icon"><i class="fas fa-tag"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Current Plan</span>
                            <span v-if="referrals <= 10" class="info-box-number">Downline (10% Commissions)</span>
                            <span v-if="referrals > 10 && referrals <= 15" class="info-box-number">Mid-line (15% Commissions)</span>
                            <span v-if="referrals >= 16" class="info-box-number">Up-line (20% Commissions)</span>
                        </div>
                <!-- /.info-box-content -->
                    </div>
                </div>
            </div>
            <!-- /.row -->
            <div v-if="notice != null" class="row">
                <div class="col-12">
                <div class="card mb-4">
                    <img :src="`/storage/images/marvellous_residents-100.jpg`" class="card-img-top" alt="..." height="280" width="1520">
                    <div class="card-body">
                        <h5 class="card-title">{{notice.title}}</h5>
                        <p class="card-text">{{notice.description}}</p>
                        <p class="card-text"><small class="text-muted">{{notice.created_at | myDate}}</small></p>
                    </div>
                </div>
                </div>
            </div>
            <!-- /.row -->
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col mb-4" v-for="property in properties" :key="property.id">
                    <div class="card">
                        <router-link :to="{name: 'property-detail', params: {id: property.id}}">
                        <img :src="`/storage/`+`${property.image}`" alt="property_img" class="card-img-top">
                        </router-link>
                        <div class="card-body">
                        <router-link :to="{name: 'property-detail', params: {id: property.id}}">
                            <h3 class="card-title">{{property.title}}</h3>
                        </router-link>
                        <p class="card-text">{{property.description | truncate(10, '...')}}</p>
                        </div>
                        <div class="card-footer">
                            <p class="text-muted">Price: <strong>₦ {{property.price | formatMoney}}</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div><!--/. container-fluid -->
    </section>
</template>

<script>
    export default {
        props: ['property'],
        data (){
            return {
                properties: {},
                referrals: {},
                notice:{},
                profile:{},
                salesTotal: {},
                commissionTotal: {},
                referred:{},
                sales : {},
                commissions : {},
                propertyTotal: {},
            }
        },
        methods: {
            loadDashboard(){
                if(this.$gate.isUser()){
                    axios.get("/api/user/dashboard").then(
                        ({ data }) => (
                            this.properties = data.data[0],
                            this.referrals = data.data[1],
                            this.notice = data.data[2][0],
                            this.profile = data.data[3],
                            this.salesTotal = data.data[4],
                            this.commissionTotal = data.data[5],
                            this.referred = data.data[6],
                            this.sales = data.data[7],
                            this.commission = data.data[8],
                            this.propertyTotal = data.data[9]
                        ),
                    );
                }
            },
            getRoute(){
                return window.location.origin;
            }
        },

        mounted() {
            console.log('Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.loadDashboard();
            this.$Progress.finish();
        }
    }
</script>
