<style scoped>
.typeahead {
  position: relative;
  display: block;
  background: #fff;
}

.typeahead-open {
  border-bottom: 0;
}

.typeahead-open .form-control {
  background: #fff;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border: 1px solid #f2f2f2;
}

.typeahead-inner {
  position: relative;
}

.typeahead-selected {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  line-height: 15px;
}

.typeahead-dropdown {
  width: 100%;
  position: absolute;
  z-index: 45;
  padding: 5px;
  background: #fff;
  border-right: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
}

.typeahead-input {
  line-height: 13px;
  font-size: 13px;
  background: #fafafa;
  border: none;
  border-radius: 1px;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
  width: 100%;
  display: block;
}

.typeahead-input:focus {
  outline-style: dotted;
  outline-width: 1px;
  outline-offset: 1px;
}

.typeahead-input_wrap {
  position: relative;
}

.typeahead-list {
  display: block;
  margin: 0;
  padding: 0;
}

.typeahead-item {
  display: block;
  margin-bottom: 1px;
}

.typeahead-link {
  cursor: pointer;
  display: block;
  padding: 2px;
  background: #fff;
}

.typeahead-active {
  background: #3aa3e3 !important;
  color: #fff;
}
</style>
<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Sales Commission</h3>

                <div class="card-tools">

                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Account Name</th>
                      <th>Account Number</th>
                      <th>Amount Paid</th>
                      <th>Commission Type</th>
                      <th>Payment Date</th>
                      <th>Property Sold</th>
                      <th>Affiliate ID</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="commission in commissions.data" :key="commission.id">

                      <td>{{commission.id}}</td>
                      <td>{{commission.account_name}}</td>
                      <td>{{commission.account_number}}</td>
                      <td>{{commission.amount}}</td>
                      <td>{{commission.commission_type}}</td>
                      <td>{{commission.date}}</td>
                      <td>{{commission.property.title}}</td>
                      <td>{{commission.affiliate_id}}</td>
                      <td>{{commission.description | truncate(30, '...')}}</td>
                      <td>

                        <a href="#" @click="editModal(commission)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteUser(commission.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="commissions" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Commission</h5>
                    <h5 class="modal-title" v-show="editmode">Update Commission's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateCommission() : createCommission()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Affiliate</label>
                            <select class="form-control" v-model="form.affiliate_id">
                              <option v-for="(prop, index) in affiliates" :key="index"
                              :value="index"
                              :selected="index == form.affiliate_id"
                              >{{prop}}</option>
                            </select>
                            <has-error :form="form" field="affiliate_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Account Name</label>
                            <input v-model="form.account_name" type="text" name="account_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('account_name') }">
                            <has-error :form="form" field="account_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Account Number</label>
                            <input v-model="form.account_number" type="text" name="account_number"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('account_number') }">
                            <has-error :form="form" field="account_number"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Amount</label>
                            <input v-model="form.amount" type="text" name="amount"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('amount') }">
                            <has-error :form="form" field="amount"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Commission Type</label>
                            <input v-model="form.commission_type" type="text" name="commission_type"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('commission_type') }">
                            <has-error :form="form" field="commission_type"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Date</label>
                            <input v-model="form.date" type="text" name="date"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('date') }">
                            <has-error :form="form" field="date"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Property</label>
                            <select class="form-control" v-model="form.property_id">
                              <option
                                  v-for="(prop,index) in properties" :key="index"
                                  :value="index"
                                  :selected="index == form.property_id">{{ prop }}</option>
                            </select>
                            <has-error :form="form" field="property_id"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Description</label>
                                <textarea v-model="form.description" name="description" id="" cols="30" rows="10"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import {Typeahead} from '../typeahead';
import Vue from 'vue';
    export default {
        data () {
            return {
                components: {Typeahead},
                editmode: false,
                commissions : {},
                form: new Form({
                    id : '',
                    commission_type : '',
                    account_name: '',
                    account_number: '',
                    amount: '',
                    date: '',
                    description: '',
                    property_id: '',
                    affiliate_id: '',
                }),
                propertyURL: "/api/admin/properties",
                affiliateURL: "/api/affiliates",
                properties: [],
                affiliates: [],
            }
        },
        methods: {
            getResults(page = 1) {

                  this.$Progress.start();

                  axios.get('/api/admin/commissions?page=' + page).then(({ data }) => (this.commissions = data.data));

                  this.$Progress.finish();
            },
            loadProperties(){
              axios.get("/api/property/list").then(({ data }) => (this.properties = data.data));
            },
            loadAffiliates(){
              axios.get("/api/affiliate/list").then(({ data }) => (this.affiliates = data.data));
            },
            loadCommissions(){
            this.$Progress.start();

            // if(this.$gate.isAdmin()){
              axios.get("/api/admin/commissions").then(({ data }) => (this.commissions = data.data));
            // }
            this.$Progress.finish();
            },

            updateCommission(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('/api/admin/commissions/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadCommissions();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(commission){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(commission);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteCommission(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('/api/admin/commissions/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadCommissions();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },


          createCommission(){

              this.form.post('/api/admin/commissions')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadCommissions();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {

        },
        created() {

            this.$Progress.start();
            this.loadCommissions();
            this.loadProperties();
            this.loadAffiliates();
            this.$Progress.finish();
        }
    }
</script>
