<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Inspection List</h3>

                <div class="card-tools">

                  <!-- <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#addNew">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button> -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                        <th>User</th>
                        <th>Title</th>
                        <th>Property</th>
                        <th>Time</th>
                        <th>Date</th>
                        <th>Address</th>
                        <th>Status</th>
                        <th>Description</th>
                        <th class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="inspection in inspections.data" :key="inspection.id">
                      <td>{{inspection.user.name}}</td>
                        <td>{{inspection.title}}</td>
                        <td>{{inspection.property.title}}</td>
                        <td>{{inspection.time}}</td>
                        <td>{{inspection.date}}</td>
                        <td>{{inspection.address}}</td>
                      <td>
                            <span v-if="inspection.status==`pending`" class="badge badge-danger">Pending</span>
                            <span v-else class="badge badge-success">Confirmed</span>
                        </td>
                      <td>{{inspection.description | truncate(30, '...')}}</td>
						<td>
                          <a href="#" @click="editModal(inspection)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteInspection(inspection.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="inspections" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="editmode">Edit Inspection</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode && updateInspection()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Title</label>
                            <input v-model="form.title" type="text" name="title"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('title') }" readonly>
                            <has-error :form="form" field="title"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Property</label>
                            <select class="form-control" v-model="form.property_id" readonly>
                              <option
                                  v-for="(prop,index) in properties" :key="index"
                                  :value="index"
                                  :selected="index == form.property_id">{{ prop }}</option>
                            </select>
                            <has-error :form="form" field="property_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Time</label>
                            <input v-model="form.time" type="text" name="time"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('time') }" readonly>
                            <has-error :form="form" field="time"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Date</label>
                            <input v-model="form.date" type="date" name="date"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('date') }" readonly>
                            <has-error :form="form" field="date"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Address</label>
                            <input v-model="form.address" type="text" name="address"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('address') }" readonly>
                            <has-error :form="form" field="address"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Update Status</label>
                                <select v-model="form.status" name="status" class="form-control show-tick"
                                :class="{ 'is-invalid': form.errors.has('status') }">
                                    <option disabled value="">-- Please select --</option>
                                    <option v-for="status in statuses" :value="status" :key="status.id">{{status}}</option>
                                </select>
                            <has-error :form="form" field="status"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                                <textarea v-model="form.description" name="description" id="" cols="30" rows="10"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Update</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
              editmode: false,
              inspections : {},
              statuses:[
                  'pending',
                  'confirmed'
              ],
              // Create a new form instance
              form: new Form({
                  id : '',
                  title: '',
                  property_id: '',
                  property: '',
                  date: '',
                  description: '',
                  time: '',
                  status: '',
                  address: '',
                  user: '',
              }),
              properties: [],
            }
        },
        methods: {
            getResults(page = 1) {
              this.$Progress.start();
              axios.get("/api/admin/inspections?page=" + page).then(({ data }) => (this.inspections = data.data));
              this.$Progress.finish();
          },

          loadInspections(){
            // if(this.$gate.isAdmin()){
              axios.get("/api/admin/inspections").then(({ data }) => (this.inspections = data.data));
            // }
          },
          loadProperties(){
              axios.get("/api/property/list").then(({ data }) => (this.properties = data.data));
          },
           editModal(inspection){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(inspection);
          },

          updateInspection(){
              this.$Progress.start();

              this.form.put("/api/admin/inspections/"+this.form.id)
              .then((data)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadInspections();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          deleteInspection(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/admin/inspections/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadInspections();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {

        },
        created() {
            this.$Progress.start();

            this.loadInspections();
            this.loadProperties();
            this.$Progress.finish();
        }
    }
</script>
