export default [
    {
        path: "/dashboard",
        component: require("./components/Dashboard.vue").default
    },
    {
        path: "/admin/dashboard",
        name: "admin-dashboard",
        component: require("./components/admin/Dashboard.vue").default
    },
    {
        path: "/admin/sales",
        component: require("./components/admin/Sales.vue").default
    },
    {
        path: "/admin/commissions",
        component: require("./components/admin/Commission.vue").default
    },
    {
        path: "/admin/inspections",
        component: require("./components/admin/Inspection.vue").default
    },
    {
        path: "/admin/notices",
        component: require("./components/admin/Notice.vue").default
    },
    {
        path: "/admin/properties",
        component: require("./components/admin/Property.vue").default
    },
    {
        path: "/admin/customers",
        component: require("./components/admin/Customer.vue").default
    },
    {
        path: "/admin/invoices/create",
        component: require("./components/admin/invoices/Form.vue").default
    },
    {
        path: "/admin/invoices/:id/edit",
        component: require("./components/admin/invoices/Form.vue").default,
        meta: { mode: "edit" }
    },
    {
        path: "/admin/invoices/:id",
        component: require("./components/admin/invoices/Show.vue").default,
        props: true
    },
    {
        path: "/admin/invoices",
        component: require("./components/admin/invoices/Index.vue").default
    },

    /////////////////////////user

    {
        path: "/user/dashboard",
        name: "user-dashboard",
        component: require("./components/user/Dashboard.vue").default
    },
    {
        path: "/user/sales",
        component: require("./components/user/Sales.vue").default
    },
    {
        path: "/user/commissions",
        component: require("./components/user/Commission.vue").default
    },
    {
        path: "/user/inspections",
        component: require("./components/user/Inspection.vue").default
    },
    {
        path: "/user/notices",
        component: require("./components/user/Notice.vue").default
    },
    {
        path: "/user/teamates",
        component: require("./components/user/Teamates.vue").default
    },
    {
        path: "/user/properties",
        component: require("./components/user/Property.vue").default
    },
    {
        path: "/user/customers",
        component: require("./components/user/Customer.vue").default
    },
    {
        path: "/user/faq",
        component: require("./components/user/Faq.vue").default
    },

    {
        path: "/developer",
        component: require("./components/Developer.vue").default
    },

    {
        path: "/profile",
        component: require("./components/Profile.vue").default
    },
    { path: "/users", component: require("./components/Users.vue").default },
    {
        path: "/products",
        component: require("./components/product/Products.vue").default
    },
    {
        path: "/customers",
        component: require("./components/Customers.vue").default
    },
    {
        path: "/affiliates",
        component: require("./components/Affiliates.vue").default
    },
    {
        path: "/affiliate/:id",
        name: "affiliate-detail",
        component: require("./components/AffiliateDetail.vue").default,
        props: true
    },
    {
        path: "/property/:id",
        name: "property-detail",
        component: require("./components/PropertyDetail.vue").default,
        props: true
    },
    {
        path: "/product/tag",
        component: require("./components/product/Tag.vue").default
    },
    {
        path: "/product/category",
        component: require("./components/product/Category.vue").default
    },

    {
        path: "/js/vendor/swiper/swiper-bundle.min.js.map",
        component: require("./components/Dashboard.vue").default
    },

    { path: "*", component: require("./components/NotFound.vue").default }
];
