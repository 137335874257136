<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Property List</h3>
                <div class="card-tools">

                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Category</th>
                      <th>Outright (&#x20A6;)</th>
                      <th>12 Months (&#x20A6;)</th>
                      <th>30 Months (&#x20A6;)</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="property in properties.data" :key="property.id">

                      <td>{{property.id}}</td>
                      <router-link :to="{name: 'property-detail', params: {id: property.id}}">
                        <td>{{property.title}}</td>
                      </router-link>
                      <td>{{property.description | truncate(30, '...')}}</td>
                      <td>{{property.type}}</td>
                      <td>{{property.priceOutright | formatMoney}}</td>
                      <td>{{property.price12Months | formatMoney}}</td>
                      <td>{{property.price30Months | formatMoney}}</td>
                      <td>

                        <a href="#" @click="editModal(property)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteProperty(property.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="properties" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Property</h5>
                    <h5 class="modal-title" v-show="editmode">Edit Property</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form method="POST" @submit.prevent="editmode ? updateProperty() : createProperty()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Title</label>
                                        <textarea v-model="form.title" name="title" cols="10" rows="2"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('title') }"></textarea>
                                        <has-error :form="form" field="title"></has-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Location</label>
                                    <input v-model="form.address" type="text" name="address"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('address') }">
                                    <has-error :form="form" field="address"></has-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Description</label>
                                        <textarea v-model="form.description" name="description" id="" cols="30" rows="10"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                                    <has-error :form="form" field="description"></has-error>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Outright</label>
                                    <input v-model="form.priceOutright" type="text" name="priceOutright"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('priceOutright') }">
                                    <has-error :form="form" field="priceOutright"></has-error>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>12 Months</label>
                                    <input v-model="form.price12Months" type="text" name="price12Months"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('price12Months') }">
                                    <has-error :form="form" field="price12Months"></has-error>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>30 Months</label>
                                    <input v-model="form.price30Months" type="text" name="price30Months"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('price30Months') }">
                                    <has-error :form="form" field="price30Months"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Bedroom </label> (Optional)
                                    <input v-model="form.bedroom" type="text" name="bedroom"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('bedroom') }">
                                    <has-error :form="form" field="bedroom"></has-error>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Bathroom </label> (Optional)
                                    <input v-model="form.bathroom" type="text" name="bathroom"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('bathroom') }">
                                    <has-error :form="form" field="bathroom"></has-error>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="form-group">
                            <label>City</label>
                            <input v-model="form.city" type="text" name="city"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('city') }">
                            <has-error :form="form" field="city"></has-error>
                        </div> -->

                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Area</label>
                                    <input v-model="form.area" type="text" name="area"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('area') }">
                                    <has-error :form="form" field="area"></has-error>
                                    <div class="help-info">Square Meter</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Nearby</label>
                                    <input v-model="form.nearby" type="text" name="nearby"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('nearby') }">
                                    <has-error :form="form" field="nearby"></has-error>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Image</label>
                                <input @change="handleOnchange" type="file" name="image"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('image') }">
                            <img v-bind:src="imagePreview" width="100" height="100" v-show="showPreview"/>
                            <has-error :form="form" field="image"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="featured">Featured</label>
                                <input v-model="form.featured" type="checkbox" name="featured"
                                class="filled-in" :class="{ 'is-invalid': form.errors.has('featured') }">
                                <has-error :form="form" field="featured"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Video URL</label>
                                <input v-model="form.video" type="url" name="video"
                                class="filled-in form-control" :class="{ 'is-invalid': form.errors.has('video') }">
                                <has-error :form="form" field="video"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Map URL</label>
                            <input v-model="form.map" type="text" name="map"
                                class="filled-in form-control" :class="{ 'is-invalid': form.errors.has('map') }">
                                <has-error :form="form" field="map"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Select type</label>
                            <select class="form-control" v-model="form.type" :class="{ 'is-invalid': form.errors.has('type') }">
                                <option disabled value="">-- Please select --</option>
                                <option value="house">House</option>
                                <option value="land">Land</option>
                                <option value="apartment">Apartment</option>
                            </select>
                                <has-error :form="form" field="type"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Select purpose</label>
                            <select class="form-control" v-model="form.purpose" :class="{ 'is-invalid': form.errors.has('purpose') }">
                                <option disabled value="">-- Please select --</option>
                                <option value="sale">Sales</option>
                                <option value="rent">Rent</option>
                                <option value="investment">Investment</option>
                            </select>
                                <has-error :form="form" field="purpose"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    export default {
        props: ['property'],
        data () {
            return {
                editmode: false,
                properties : {},
                imagePreview: null,
                showPreview: false,
                form: new Form({
                    id: '',
                    image: '',
                    title: '',
                    description: '',
                    priceOutright: '',
                    price12Months: '',
                    price30Months: '',
                    purpose: '',
                    area: '',
                    address: '',
                    type: '',
                    video: '',
                    map: '',
                    floor_plan: '',
                    bathroom: '',
                    bedroom: '',
                    featured: '1',
                    nearby: '',
                    city: '',
                    dropzoneOptions: {
                    url: '/store-multiple-image',
                    headers: {
                        "X-CSRF-TOKEN": document.head.querySelector("[name=csrf-token]").content
                    }}
                }),
                autocompleteItems: [],
            }
        },
        methods: {
            handleOnchange(event){
            /*
            Set the local file variable to what the user has selected.
            */
            this.form.image = event.target.files[0];

            /*
            Initialize a File Reader object
            */
            let reader  = new FileReader();

            /*
            Add an event listener to the reader that when the file
            has been loaded, we flag the show preview as true and set the
            image to be what was read from the reader.
            */
            reader.addEventListener("load", function () {
                this.showPreview = true;
                this.imagePreview = reader.result;
            }.bind(this), false);

            /*
            Check to see if the file is not empty.
            */
            if( this.form.image ){
                /*
                    Ensure the file is an image file.
                */
                if ( /\.(jpe?g|png|gif)$/i.test( this.form.image.name ) ) {
                    /*
                    Fire the readAsDataURL method which will read the file in and
                    upon completion fire a 'load' event which we will listen to and
                    display the image in the preview.
                    */
                    reader.readAsDataURL( this.form.image );
                }
            }
        },
          getResults(page = 1) {
              this.$Progress.start();
              axios.get('/api/admin/property?page=' + page).then(({ data }) => (this.properties = data.data));
              this.$Progress.finish();
          },
          loadProperties(){
            if(this.$gate.isAdmin()){
              axios.get("/api/admin/property").then(({ data }) => (this.properties = data.data));
            }
          },
          editModal(property){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(property);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createProperty(){
            const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
              this.$Progress.start();
              const fd = new FormData();
                fd.append('image', this.form.image)
                fd.append('address', this.form.address)
                fd.append('area', this.form.area)
                fd.append('city', this.form.city)
                fd.append('description', this.form.description)
                fd.append('priceOutright', this.form.priceOutright)
                fd.append('price12Months', this.form.price12Months)
                fd.append('price30Months', this.form.price30Months)
                fd.append('purpose', this.form.purpose)
                fd.append('title', this.form.title)
                fd.append('type', this.form.type)
                fd.append('video', this.form.video)
                fd.append('nearby', this.form.nearby)
                fd.append('bathroom', this.form.bathroom)
                fd.append('bedroom', this.form.bedroom)
                fd.append('map', this.form.map)
              axios.post('/api/admin/property', fd, config)
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');
                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProperties();
                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
                  this.$Progress.failed();
                }
              })
              .catch(()=>{
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProperty(){
              this.$Progress.start();
              this.form.put('/api/admin/property/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');
                  this.loadProperties();
              })
              .catch(() => {
                  this.$Progress.fail();
              });
          },
          deleteProperty(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/admin/property/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProperties();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        },
        mounted() {

        },
        created() {
            this.$Progress.start();
            this.loadProperties();
            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
          },
        },
    }
</script>
