<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header">
                <h3 class="card-title">Direct Sales</h3>

                <div class="card-tools">

                  <!-- <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button> -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>SL.</th>
                      <th>Sold By</th>
                      <th>Client</th>
                      <th>Amount</th>
                      <th>Bank</th>
                      <th>Payment Type</th>
                      <th>Payment Date</th>
                      <th>Property</th>
                      <th>Status</th>
                      <th width="150">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="sale in sales.data" :key="sale.id">

                      <td>{{sale.id}}</td>
                      <td>{{sale.user.name}}</td>
                      <td>{{sale.name}}</td>
                      <td>{{sale.amount}}</td>
                      <td>{{sale.bank_name}}</td>
                      <td>{{sale.payment_type}}</td>
                      <td>{{sale.date}}</td>
                      <td>{{sale.property.title}}</td>
                      <td>
                            <span v-if="sale.status==`pending`" class="badge badge-danger">Pending</span>
                            <span v-else class="badge badge-success">Confirmed</span>
                        </td>
                      <td>

                        <a href="#" @click="editModal(sale)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteSale(sale.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="sales" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Direct Sales</h5>
                    <h5 class="modal-title" v-show="editmode">Update Sales's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateSale() : createSale()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }" readonly>
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Amount</label>
                            <input v-model="form.amount" type="text" name="amount"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('amount') }" readonly>
                            <has-error :form="form" field="amount"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Bank Name</label>
                            <input v-model="form.bank_name" type="text" name="bank_name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('bank_name') }" readonly>
                            <has-error :form="form" field="bank_name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Payment Type</label>
                            <input v-model="form.payment_type" type="text" name="payment_type"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('payment_type') }" readonly>
                            <has-error :form="form" field="payment_type"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Date</label>
                            <input v-model="form.date" type="text" name="date"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('date') }" readonly>
                            <has-error :form="form" field="date"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                                <textarea v-model="form.description" name="description" id="" cols="30" rows="10"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Update Status</label>
                                <select v-model="form.status" name="status" class="form-control show-tick"
                                :class="{ 'is-invalid': form.errors.has('status') }">
                                    <option disabled value="">-- Please select --</option>
                                    <option v-for="status in statuses" :value="status" :key="status.id">{{status}}</option>
                                </select>
                            <has-error :form="form" field="status"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Property</label>
                            <select class="form-control" v-model="form.property_id" readonly>
                              <option
                                  v-for="(prop,index) in properties" :key="index"
                                  :value="index"
                                  :selected="index == form.property_id">{{ prop }}</option>
                            </select>
                            <has-error :form="form" field="property_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Affiliates</label>
                            <select class="form-control" v-model="form.user_id" readonly>
                              <option
                                  v-for="(aff,index) in affiliates" :key="index"
                                  :value="index"
                                  :selected="index == form.user_id">{{ aff }}</option>
                            </select>
                            <has-error :form="form" field="user_id"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                sales : {},
                statuses:[
                  'pending',
                  'confirmed'
                ],
                form: new Form({
                    id : '',
                    payment_type : '',
                    amount: '',
                    name: '',
                    description: '',
                    date: '',
                    bank_name: '',
                    status: '',
                    property_id: '',
                    property: '',
                    user_id: '',
                    user: '',
                }),
                properties: [],
                affiliates: [],
            }
        },
        methods: {

            getResults(page = 1) {

                  this.$Progress.start();

                  axios.get('/api/admin/sales?page=' + page).then(({ data }) => (this.sales = data.data));

                  this.$Progress.finish();
            },
            loadProperties(){
              axios.get("/api/property/list").then(({ data }) => (this.properties = data.data));
            },
            loadAffiliates(){
              axios.get("/api/seller/list").then(({ data }) => (this.affiliates = data.data));
            },
            loadSales(){
            // if(this.$gate.isAdmin()){
              axios.get("/api/admin/sales").then(({ data }) => (this.sales = data.data));
            // }
          },
            updateSale(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('/api/admin/sales/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadSales();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(sale){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(sale);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteSale(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('/api/admin/sales/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadSales();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },

          createSale(){

              this.form.post('/api/admin/sales')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadSales();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
        },
        created() {

            this.$Progress.start();
            this.loadSales();
            this.loadAffiliates()
            this.loadProperties();
            this.$Progress.finish();
        }
    }
</script>
