<template>
    <section class="content">
        <div class="container-fluid">
           <div class="row row-cols-1 row-cols-md-2">
                <div class="col mb-4" v-for="property in properties.data" :key="property.id">
                    <div class="card">
                        <img src="https://via.placeholder.com/520x280.png" class="card-img-top" alt="#">
                        <div class="card-body">
                        <router-link :to="{name: 'property-detail', params: {id: property.id}}">
                            <h5 class="card-title">{{property.title}}</h5>
                        </router-link>
                        <p class="card-text">{{property.description}}</p>
                        </div>
                        <div class="card-footer">
                            <small class="text-muted">₦ {{property.price}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: ['property'],
        data () {
            return {
              properties : {},
            }
        },
        methods: {
          loadProperties(){
              axios.get("/api/admin/property").then(({ data }) => (this.properties = data.data));
          },

        },
        mounted() {
            console.log('Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.loadProperties();
            this.$Progress.finish();
        }
    }
</script>
