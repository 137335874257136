<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card" v-if="$gate.isUser()">
              <div class="card-header">
                <h3 class="card-title">My Customers</h3>

                <div class="card-tools">

                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New Customer
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>SL.</th>
                      <!-- <th>Sold By</th> -->
                      <th>Firstname</th>
                      <th>Lastname</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Address</th>
                      <th>Description</th>
                      <!-- <th>City</th>
                      <th>Country</th> -->
                      <th width="150">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="customer in customers.data" :key="customer.id">

                      <td>{{customer.id}}</td>
                      <!-- <td>{{customer.user_id}}</td> -->
                      <td>{{customer.firstname}}</td>
                      <td>{{customer.lastname}}</td>
                      <td>{{customer.phone}}</td>
                      <td>{{customer.email}}</td>
                      <td>{{customer.address}}</td>
                      <td>{{customer.description}}</td>
                      <!-- <td>{{customer.city}}</td>
                      <td>{{customer.country}}</td> -->
                      <td>

                        <a href="#" @click="editModal(customer)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteCustomer(customer.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="customers" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Customer</h5>
                    <h5 class="modal-title" v-show="editmode">Update Customers's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateCustomer() : createCustomer()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>First Name</label>
                            <input v-model="form.firstname" type="text" name="firstname"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('firstname') }">
                            <has-error :form="form" field="firstname"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Last Name</label>
                            <input v-model="form.lastname" type="text" name="lastname"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('lastname') }">
                            <has-error :form="form" field="lastname"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Phone</label>
                            <input v-model="form.phone" type="text" name="phone"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('phone') }">
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="email" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                                <textarea v-model="form.description" name="description" id="" cols="30" rows="10"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Address</label>
                            <input v-model="form.address" type="text" name="address"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('address') }">
                            <has-error :form="form" field="address"></has-error>
                        </div>
                        <div class="form-group">
                            <label>City</label>
                            <input v-model="form.city" type="text" name="city"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('city') }">
                            <has-error :form="form" field="city"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Country</label>
                            <input v-model="form.country" type="text" name="country"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('country') }">
                            <has-error :form="form" field="country"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                customers : {},
                form: new Form({
                    id : '',
                    firstname : '',
                    lastname : '',
                    phone: '',
                    email: '',
                    description: '',
                    address: '',
                    city: '',
                    country: '',
                })
            }
        },
        methods: {

            getResults(page = 1) {

                  this.$Progress.start();

                  axios.get('/api/user/customers?page=' + page).then(({ data }) => (this.customers = data.data));

                  this.$Progress.finish();
            },
            loadCustomers(){
            // if(this.$gate.isUser()){
              axios.get("/api/user/customers").then(({ data }) => (this.customers = data.data));
            // }
          },
            updateCustomer(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.put('/api/user/customers/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadCustomers();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(customer){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(customer);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteCustomer(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {

                        // Send request to the server
                         if (result.value) {
                                this.form.delete('/api/user/customers/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your Customer has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadCustomers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                         }
                    })
            },

          createCustomer(){

              this.form.post('/api/user/customers')
              .then((response)=>{
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: response.data.message
                  });

                  this.$Progress.finish();
                  this.loadCustomers();

              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          }

        },
        mounted() {
        },
        created() {

            this.$Progress.start();
            this.loadCustomers()
            this.$Progress.finish();
        }
    }
</script>
