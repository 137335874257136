<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title"><strong>Affiliate Details</strong></h3>
                            <div class="card-tools">
                            <router-link to="/affiliates" class="btn btn-primary">
                                Back
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body">
                            <p><strong>Name:</strong> {{affiliate.name}}</p>
                            <p><strong>Phone:</strong> {{affiliate.phone}}</p>
                            <p><strong>Email:</strong> {{affiliate.email}}</p>
                            <p><strong>Username:</strong> {{affiliate.username}}</p>
                            <p><strong>Address:</strong> {{affiliate.address}}</p>
                            <p><strong>Bank Account:</strong> {{affiliate.bank_account}}</p>
                            <p><strong>Bank Account Name:</strong> {{affiliate.bank_account_name}}</p>
                            <p><strong>Bank Account Number:</strong> {{affiliate.bank_account_number}}</p>
                            <p><strong>Next of Kins Name:</strong> {{affiliate.next_of_kins_name}}</p>
                            <p><strong>Next of Kins Number:</strong> {{affiliate.next_of_kins_number}}</p>
                            <p><strong>Referrer:</strong> {{affiliate.referredBy}}</p>
                            <p><strong>Joined:</strong> {{affiliate.created_at | myDate}}</p>
                        </div>
                        <div class="card-footer">
                            <span class="btn btn-sm disabled btn-primary">
                                <span v-if="referralsCount <= 10" class="info-box-number">Downline (10% Commissions)</span>
                                <span v-if="referralsCount > 10 && referralsCount <= 15" class="info-box-number">Mid-line (15% Commissions)</span>
                                <span v-if="referralsCount >= 16" class="info-box-number">Up-line (20% Commissions)</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-header">
                            <ul class="card-title nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Sales</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Referrals</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Customers</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="pills-commission-tab" data-toggle="pill" href="#pills-commission" role="tab" aria-controls="pills-commission" aria-selected="false">Commissions</a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body">
                            <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <h4>Total Sales: {{salesCount}}</h4>
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title"> Sales</h3>

                                        <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        </div>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body p-0">
                                        <div class="table-responsive">
                                        <table class="table m-0">
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Name</th>
                                                    <th>Price (&#x20A6;)</th>
                                                    <th>Item</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="sale in sales" :key="sale.id">
                                                    <td>{{sale.id}}</td>
                                                    <td>{{sale.name}}</td>
                                                    <td>{{sale.amount}}</td>
                                                    <td>{{sale.property.title}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                <!-- /.card-body -->
                                </div>
                                <!-- /.card -->
                                <!-- /.card -->
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <h4>Total: {{referralsCount}}</h4>
                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <h4>Total Customers: {{customersCount}}</h4>
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title"> Customers</h3>

                                        <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        </div>
                                    </div>
                                    <!-- /.card-header -->
                                    <div class="card-body p-0">
                                        <div class="table-responsive">
                                        <table class="table m-0">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>First Name</th>
                                                    <th>Last Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="customer in customers" :key="customer.id">
                                                    <td>{{customer.id}}</td>
                                                    <td>{{customer.firstname}}</td>
                                                    <td>{{customer.lastname}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                <!-- /.card-body -->
                                </div>
                                <!-- /.card -->
                                <!-- /.card -->
                            </div>
                            <div class="tab-pane fade" id="pills-commission" role="tabpanel" aria-labelledby="pills-commission-tab">
                                <h4>Total: {{commissions}}</h4>
                            </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <h5 class="card-title"><strong>Address:</strong> {{affiliate.address}}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        props: ['id'],
        data () {
            return {
                affiliate: {},
                salesCount: {},
                sales: {},
                referralsCount: {},
                customers: {},
                customersCount: {},
                commissions:{},
            }
        },
        methods: {
            loadAffiliates(){
                if(this.$gate.isAdmin()){
                    axios.get("/api/affiliate/"+ this.id).then(
                        ({ data }) => (
                            this.affiliate = data.data[0],
                            this.salesCount = data.data[1],
                            this.sales = data.data[2],
                            this.referralsCount = data.data[3],
                            this.customers = data.data[4],
                            this.customersCount = data.data[5],
                            this.commissions = data.data[6]
                        ),
                    );
                }
            },
        },

        mounted() {
            console.log('Component mounted.')
        },
        created() {

            this.$Progress.start();
            this.loadAffiliates();
            this.$Progress.finish();
        }
    }
</script>
