<template>

</template>
<script>
export default {

    methods: {
        loadDashboard(){
            if (this.$gate.isAdmin()) {
                this.$router.push("/admin/dashboard");
            }else if (this.$gate.isUser()){
                this.$router.push("/user/dashboard");
            }else {
                this.$router.push({path: "/home"});
            }
        }
    },
    created() {
            this.$Progress.start();
            this.loadDashboard();
            this.$Progress.finish();
    }
}
</script>


