<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="card-title">My Team</h3>

                <div class="card-tools">

                  <!-- <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#addNew">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button> -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>SL.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Username</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="teamate in teamates.data" :key="teamate.id">

                      <td>{{teamate.id}}</td>
                      <td>{{teamate.name}}</td>
                      <td>{{teamate.email}}</td>
                      <td>{{teamate.phone}}</td>
                      <td>{{teamate.username}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="teamates" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
              teamates : {},
            }
        },
        methods: {
            getResults(page = 1) {
                  this.$Progress.start();
                  axios.get('/api/user/teams?page=' + page).then(({ data }) => (this.teamates = data.data));
                  this.$Progress.finish();
            },

          loadTeamates(){
            // if(this.$gate.isUser()){
              axios.get("/api/user/teams").then(({ data }) => (this.teamates = data.data));
            // }
          },
        },

        mounted() {

        },

        created() {
            this.$Progress.start();
            this.loadTeamates();
            this.$Progress.finish();
        },


    }
</script>
