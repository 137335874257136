<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-9">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title"><strong>{{property.title}}</strong></h3>
                            <div class="card-tools" v-if="$gate.isAdmin()">
                                <router-link to="/admin/properties" class="btn btn-primary">
                                Back
                                </router-link>
                            </div>
                            <div class="card-tools" v-if="!$gate.isAdmin()">
                                <router-link to="/user/dashboard" class="btn btn-primary">
                                Back
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body">
                            <img :src="`/storage/${property.image}`" alt="property_img" class="card-img-top">

                            <br><br><br>
                            <p>{{property.description}}</p>
                            <span class="btn btn-sm disabled btn-secondary" v-show="property.bedroom !== null">Bedroom: {{ property.bedroom}} </span>
                            <span class="btn btn-sm disabled btn-secondary" v-show="property.bathroom !== null">Bathroom: {{ property.bathroom}} </span>
                            <span class="btn btn-sm disabled btn-danger">Area: {{ property.area}} Sq Mtr</span>
                        </div>
                        <div class="card-footer">
                            <h5 class="card-title"><strong>Address:</strong> {{property.address}}</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title"><strong>Outright Price:</strong></h3>
                            <div class="card-tools"></div>
                        </div>
                        <div class="card-body">
                            <h4 class="left">&#x20A6;{{property.priceOutright | formatMoney}}</h4>
                        </div>
                        <div class="card-footer">
                            <span class="btn btn-sm disabled btn-primary"><strong>Purpose:</strong> {{ property.purpose}} </span>
                            <span class="btn btn-sm disabled btn-primary"><strong>Type:</strong> {{ property.type}} </span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title"><strong>12 Months Price:</strong></h3>
                            <div class="card-tools"></div>
                        </div>
                        <div class="card-body">
                            <h4 class="left">&#x20A6;{{property.price12Months | formatMoney}}</h4>
                        </div>
                        <div class="card-footer">
                            <span class="btn btn-sm disabled btn-primary"><strong>Purpose:</strong> {{ property.purpose}} </span>
                            <span class="btn btn-sm disabled btn-primary"><strong>Type:</strong> {{ property.type}} </span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title"><strong>30 Months Price:</strong></h3>
                            <div class="card-tools"></div>
                        </div>
                        <div class="card-body">
                            <h4 class="left">&#x20A6;{{property.price30Months | formatMoney}}</h4>
                        </div>
                        <div class="card-footer">
                            <span class="btn btn-sm disabled btn-primary"><strong>Purpose:</strong> {{ property.purpose}} </span>
                            <span class="btn btn-sm disabled btn-primary"><strong>Type:</strong> {{ property.type}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <div class="card">
                        <div class="card-header">
                            <span class="btn btn-sm disabled btn-secondary">Nearby: {{ property.nearby}}</span>
                        </div>
                        <iframe id="ytplayer" type="text/html" height="360"
                        :src="'https://www.youtube.com/embed/'+property.video+'?autoplay=0&origin=http://example.com'"
                        frameborder="0"></iframe>
                        <div class="card-footer">
                            <span class="btn btn-sm disabled btn-secondary">Map: {{ property.map}} </span>
                        </div>
                    </div>
                </div>
                <div class="col-md-3"></div>
            </div>
            <div class="row">
                <!-- Left col -->
                <div class="col-md-9">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                    <div class="card-header">
                        <h3 class="card-title"><strong>Location on Map:</strong></h3>

                        <div class="card-tools">
                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus"></i>
                        </button>
                        <button type="button" class="btn btn-tool" data-card-widget="remove">
                            <i class="fas fa-times"></i>
                        </button>
                        </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body p-0">
                        <div class="table-responsive">
                        </div>
                    </div>
                <!-- /.card-body -->
                </div>
                <!-- /.card -->
                <!-- /.card -->
            </div>
            <div class="col-md-3">
            </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        props: ['id'],
        data () {
            return {
                property: {}
            }
        },

        mounted () {
            axios.get('/api/property/'+ this.id).then( (response) => {
                this.property = response.data.data;
            });
        }
    }
</script>
